import React from "react";
import clsx from 'clsx';

import { Link } from "gatsby";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      logo: {
        width: 120,
        lineHeight: 0,
      },
    }
  ),
);

interface Props {
  size?: number;
  linkTo?: string;
  variant?: 'short' | 'admin' | 'full';
  light?: boolean;
  className?: string;
}

const Logo: React.FC<Props> = ({ size = 120, linkTo, variant = 'full', light, className }) => {
  const classes = useStyles();
  const imgUrl = light ? '/logo_light.png' : `/logo_${variant}.png`;

  return linkTo ? (
    <Link to={linkTo} className={clsx(classes.logo, className)}>
      <img src={imgUrl} style={{ width: size }} alt="Repix.app" />
    </Link>
  ) : (
      <img src={imgUrl} className={clsx(classes.logo, className)} style={{ width: size }} alt="Repix.app" />
    );
};

export default Logo;